if ('scrollRestoration' in history) {
    // Back off, browser, I got this...
    history.scrollRestoration = 'manual';
}
$(document).ready(function () {
    function smoothScroll(hash) {
        if ($(hash).offset()) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(hash).offset().top
            }, 500);
        } else {
            $([document.documentElement, document.body]).animate({
                scrollTop: 0
            }, 500);
        }
    }
    var locationHash = window.location.hash;
    if(locationHash) {
        if ($(locationHash).length) {
            smoothScroll(locationHash);
        } else {
            console.log("[Smooth Nav] ID doesn't exist");
        }
    }
    $("a.navigator").click(function (event) {
        smoothScroll(this.hash);
        window.location = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + window.location.pathname + this.hash;

    });
});
