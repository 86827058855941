$(document).ready(function () {

    //read more/less
    var readLessText = 'read less';
    var readMoreText = 'read more';
    var showClass = 'read-more-less-show';
    var CollapseClass = 'read-more-less-collapse';


    var read_more_less_elements = $('.read-more-less');

    read_more_less_elements.each(function () {
        var jq = $(this);
        var target = jq.attr('data-target');
        var targetJq = $(target);

        if (!(targetJq.hasClass(CollapseClass))) {
            targetJq.addClass(CollapseClass);
            targetJq.hide(500);
        }

        if (jq.attr('data-read-more-text')) readMoreText = jq.attr('data-read-more-text');
        if (jq.attr('data-read-less-text')) readLessText = jq.attr('data-read-less-text');


        if (targetJq.hasClass(showClass)) {
            jq.html(readLessText);
        }
        if (!(targetJq.hasClass(showClass))) {
            jq.html(readMoreText);
        }

    });

    read_more_less_elements.click(function () {
        var jq = $(this);
        var target = jq.attr('data-target');
        var targetJq = $(target);
        console.log(jq);

        if (jq.attr('data-read-more-text')) readMoreText = jq.attr('data-read-more-text');
        if (jq.attr('data-read-less-text')) readLessText = jq.attr('data-read-less-text');

        if (targetJq.hasClass(showClass)) {
            targetJq.removeClass(showClass);
            targetJq.hide(500);
            jq.html(readMoreText)
        } else {
            targetJq.addClass(showClass);
            targetJq.show(500);
            jq.html(readLessText)
        }
    });
});

